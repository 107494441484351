<template>
    <div class="payS">
        <div>
            <img src="https://weiyeglobal.oss-accelerate.aliyuncs.com/News/0ec29e94f5b0ff944ea41aae2b032db3.png" alt="">
        </div>
        <p>Pay success！</p>
        <a @click="goBack()" class="goBack">Go back</a>
    </div>
</template>

<script>
    import {defineComponent} from "vue";

    // import {useRoute, useRouter} from "vue-router";

    export default defineComponent({
        name: "paysuccess",

        methods: {
            goBack() {
                this.$router.replace("/order");
            }
        }
    });

</script>
<style scoped>
    .goBack{
        font-size: 18px;
        display: inline-block;
        padding: 5px 10px;
        background: #df0024;
        color: white;
    }
    img{
        width: 200px;
        height: 200px;
    }
.payS{
    text-align: center;
    margin-top: 50px;
}
    .payS p{
        margin:40px  0;
    }
</style>